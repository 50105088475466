<template>
  <div id="proList">
    <header-bar title="项目列表" :is-show-back-arrow="false">
      <template slot="left">
        <span @click="isShowBranchPopup = true">筛选</span>
      </template>
      <template slot="right">
        <img
          class="projectIcon"
          src="../assets/images/proList_icon.png"
          @click="$router.push({ name: 'personal' })"
        >
      </template>
    </header-bar>
    <van-tabs
      v-model="params.type"
      class="tabs"
      color="#2F8AF1"
      title-active-color="#2F8AF1"
      title-inactive-color="#333333"
      line-width="16px"
      line-height="2px"
    >
      <van-tab v-for="item in tabsArr" :key="item.value" :title="item.title" :name="item.value" />

    </van-tabs>

    <div v-if="isNoData" class="noData">
      <img src="../assets/images/proList_none.png" alt="">
      <p class="grey9">~没有待执行的项目~</p>
    </div>

    <van-list
      v-else
      v-model="isLoading"
      :finished="isLoaded"
      finished-text="没有更多了"
      @load="getProjectList"
    >
      <ul class="proList">
        <li
          v-for="(item, index) in proList"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="topWrap">
            <h3 class="f32 grey9">{{ item.name }}</h3>
            <span class="f24">{{ item.project_stauts }}</span>
          </div>
          <div class="content" :class="{'isChecked': item.check_status > 0}">
            <div class="labelItem">
              <label class="f28">项目类型：</label>
              <p class="oneClamp">{{ item.project_type }}</p>
            </div>
            <div class="labelItem">
              <label class="f28">参与单位：</label>
              <div>
                <p
                  v-for="branchItem in item.participant"
                  :key="branchItem.id"
                  class="oneClamp"
                >{{ branchItem.name }}</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </van-list>

    <confirm-popup
      title="请选择参与单位"
      :is-show="isShowBranchPopup"
      :data="branchList"
      @clickCancel="isShowBranchPopup = false"
      @clickConfirm="confirmCheckBranch"
    />

  </div>
</template>

<script>
// import { } from 'vuex'
import HeaderBar from '../components/HeaderBar/index';
import ConfirmPopup from '../components/ConfirmPopup/index';
export default {
  name: 'ProList',
  components: { ConfirmPopup, HeaderBar },
  props: [],
  data() {
    return {
      // 0-全部项目 1-实施中 2-实施结束 3-项目完成
      tabsArr: [
        { title: '全部', value: 0 },
        { title: '实施中', value: 1 },
        { title: '实施结束', value: 2 },
        { title: '项目完成', value: 3 }
      ],
      params: {
        'type': 0,
        'branch_id': 0,
        'page': 1,
        'limit': 5
      },
      proList: [],
      isShowBranchPopup: false,
      branchList: [],
      isNoData: false,
      isLoading: false,
      isLoaded: false
    };
  },
  computed: {},
  watch: {
    'params.type'() {
      this.reLoad();
    }
  },
  created() {
    this.getProjectList();
    this.http.$post(this.$api.getShopList)
      .then(res => {
        this.branchList = res.data.list;
      });
  },
  mounted() {},
  methods: {
    reLoad() {
      this.proList = [];
      this.isNoData = false;
      this.isLoaded = false;
      this.params.page = 1;
      this.getProjectList();
    },
    // 获取项目列表
    getProjectList() {
      this.isLoading = true;
      this.http.$post(this.$api.getProList, this.params)
        .then(res => {
          console.log(res);
          if (this.params.page === 1 && res.data.list.length === 0) {
            this.isNoData = true;
          } else {
            this.proList = this.proList.concat(res.data.list);
            if (res.data.list.length === 0) {
              this.isLoaded = true;
            } else {
              this.params.page++;
            }
          }
        }).finally(() => {
          this.isLoading = false;
        });
    },
    confirmCheckBranch(item) {
      this.params.branch_id = item.id;
      this.reLoad();
      this.isShowBranchPopup = false;
    },
    toDetail({ id }) {
      this.$router.push({ name: 'proDetail', params: { id }});
    }
  }

};
</script>
<style lang="scss">
  #proList{
    .van-tabs--line .van-tabs__wrap{
      height: 100px;
    }
  }

</style>
<style lang="scss" scoped>
#proList{
  padding-top: 100px;
  .projectIcon{
    width: 48px;
  }
  .tabs{
    width: 100%;
    position: fixed;
    left: 0;
    top: 92px
  }
  .proList{
    padding: 16px 24px;
    li{
      background: #fff;
      padding: 0 24px;
      border-radius: 16px;
      box-shadow: 0 0 10px 0 $color-bg;
      margin-bottom: 18px;
      .topWrap{
        padding: 26px 0;
        line-height: 44px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px $color-bg solid;
        h3{
          background: url("../assets/images/proList_title_icon.png") no-repeat left center;
          background-size: 40px;
          padding-left: 42px;
        }
        span {
          color: #004A8F;
        }
      }
      .content{
        padding: 32px 0;
        &.isChecked{
          background: url("../assets/images/proList_checked.png") no-repeat right center;
          background-size: 162px;
        }
        .labelItem{
          display: flex;
          margin-bottom: 16px;
          line-height: 40px;
          label{
            width: 140px;
          }
          p{
            width: 332px;
          }
        }
      }
    }
  }
  .noData{
    padding-top: 224px;
    text-align: center;
    img{
      width: 380px;
      margin-bottom: 24px;
    }
  }
}
</style>
